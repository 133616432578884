import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import IChecklistReportRow from "../../api/IChecklistReportRow";
import { ReportTableMatrixDetailRow } from "../report-table-details/ReportTableMatrixDetailRow";
import { TableMatrixValuesType } from "../report-table-details/api/TableMatrix";
import StepsDone from "./StepsDone";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import WorksheetStatusBadge from "common/components/badges/worksheet-status-badge/WorksheetStatusBadge";
import IconButton from "common/components/buttons/icon-button/IconButton";
import FormattedDate from "common/components/formattedDate/FormattedDate";
import {
    ApiKeyIcon,
    ScheduleIcon,
    UpdateAvailableIcon,
} from "common/components/icons";
import { TableCell, TableRow } from "common/components/table";
import TextOverflow from "common/components/text-overflow/TextOverflow";
import { MenuCommands } from "common/components/toggle-state/MenuCommands";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";

interface IProps {
    value: IChecklistReportRow;

    tableMatrixData: TableMatrixValuesType;

    selectedCustomListItemsNames?: Record<
        string,
        IValueLabelItemWithState | undefined
    >;

    isAdministrator?: boolean;

    onSelectAction: (id: string, command: MenuCommands) => void;
}

const ReportTableRow: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { value, tableMatrixData, selectedCustomListItemsNames } = props;

    const checklistId = value.id;

    const { isInitialized, columns } = tableMatrixData;

    const rowSpan = tableMatrixData.rowSpans[checklistId];

    const primaryRows = tableMatrixData.primaryRowsByChecklistId[checklistId];

    const secondaryRows =
        tableMatrixData.secondaryRowsByChecklistId[checklistId];

    const { t } = useTranslation();

    const nameCellHasTags = value.apiKeyId ?? value.scheduleId;

    const handlePreviewDeviations = () => {
        props.onSelectAction(checklistId, MenuCommands.PreviewDeviations);
    };

    const handleSelect = (command: MenuCommands) => {
        props.onSelectAction(checklistId, command);
    };

    const handlePreviewClick = () => {
        handleSelect(MenuCommands.Preview);
    };

    const handleOnBarcodeSearch = (barcode: string) => {
        props.onSelectAction(barcode, MenuCommands.BarcodeSearch);
    };

    const handleShowRecurringChecklistModal = () => {
        handleSelect(MenuCommands.PreviewRecurrence);
    };

    const hasNotCompletedDeviations =
        value.totalNotCompletedImprovementsCount > 0;

    const hasCompletedDeviations = value.totalCompletedImprovementsCount > 0;

    return (
        <React.Fragment>
            <TableRow
                isArchived={value.status === ChecklistDynamicStatus.Archived}
            >
                {nameCellHasTags ? (
                    <TableCell
                        className={
                            value.isTitleMatch
                                ? "table-cell__sticky table-cell__match-cell"
                                : "table-cell__sticky"
                        }
                        zIndex={1}
                        rowSpan={rowSpan}
                        onClick={handlePreviewClick}
                    >
                        <TextOverflow
                            className="preview-link"
                            ellipsis
                            title={value.title}
                        >
                            {value.title}
                        </TextOverflow>

                        <TableCell.Tags>
                            {value.apiKeyId && <ApiKeyIcon isActive={true} />}
                            {value.scheduleId && (
                                <IconButton
                                    tooltip={value.scheduleName}
                                    onClick={handleShowRecurringChecklistModal}
                                    testId="calendarChecklistReportBtn"
                                >
                                    <ScheduleIcon isActive={true} />
                                </IconButton>
                            )}
                        </TableCell.Tags>
                    </TableCell>
                ) : (
                    <TableCell
                        className={
                            value.isTitleMatch
                                ? "table-cell__sticky table-cell__match-cell"
                                : "table-cell__sticky"
                        }
                        zIndex={1}
                        rowSpan={rowSpan}
                        onClick={handlePreviewClick}
                    >
                        <TextOverflow
                            className="preview-link"
                            ellipsis
                            title={value.title}
                        >
                            {value.title}
                        </TextOverflow>
                    </TableCell>
                )}

                {value.hasSearchResult && (
                    <TableCell rowSpan={rowSpan}>
                        {value.totalMatchCount > 0 ? (
                            <div
                                className="preview-link"
                                onClick={handlePreviewClick}
                            >
                                {value.totalMatchCount}{" "}
                                {value.totalMatchCount === 1
                                    ? t(k.MATCHING_VALUE_)
                                    : t(k.MATCHING_VALUES_)}
                            </div>
                        ) : (
                            <React.Fragment>
                                {"0 "}
                                {t(k.MATCHING_VALUES_)}
                            </React.Fragment>
                        )}
                    </TableCell>
                )}

                {isInitialized ? undefined : (
                    <TableCell rowSpan={rowSpan}>
                        {value.templateName}
                    </TableCell>
                )}

                <TableCell useOverflow rowSpan={rowSpan}>
                    <FormattedDate
                        date={value.startDate}
                        splitDateTime
                        useFlexWrapper
                    />
                </TableCell>

                <TableCell useOverflow rowSpan={rowSpan}>
                    <FormattedDate
                        date={value.endDate}
                        splitDateTime
                        useFlexWrapper
                    />
                </TableCell>

                <TableCell rowSpan={rowSpan}>
                    V.{value.version}
                    {value.hasNewVersion && (
                        <TableCell.Tags>
                            <div
                                title={t(k.UPDATE_AVAILABLE)}
                                className="table-cell__tag"
                            >
                                <UpdateAvailableIcon />
                            </div>
                        </TableCell.Tags>
                    )}
                </TableCell>

                <TableCell rowSpan={rowSpan}>
                    <WorksheetStatusBadge
                        status={value.status}
                        completedAt={value.completedAt}
                    />
                </TableCell>

                <TableCell rowSpan={rowSpan}>
                    <StepsDone value={value} />
                </TableCell>

                <TableCell rowSpan={rowSpan}>
                    {(hasNotCompletedDeviations || hasCompletedDeviations) && (
                        <span
                            className="preview-link"
                            onClick={handlePreviewDeviations}
                        >
                            {hasNotCompletedDeviations && (
                                <React.Fragment>
                                    {value.totalNotCompletedImprovementsCount}{" "}
                                    {t(k.OPEN)}
                                </React.Fragment>
                            )}

                            {hasNotCompletedDeviations &&
                                hasCompletedDeviations && (
                                    <React.Fragment>{", "}</React.Fragment>
                                )}

                            {hasCompletedDeviations && (
                                <React.Fragment>
                                    {value.totalCompletedImprovementsCount}{" "}
                                    {t(k.COMPLETED)}
                                </React.Fragment>
                            )}
                        </span>
                    )}
                </TableCell>

                {isInitialized && (
                    <ReportTableMatrixDetailRow
                        columns={columns}
                        row={primaryRows ?? null}
                        selectedCustomListItemsNames={
                            selectedCustomListItemsNames
                        }
                        highlightedActivityInstanceIds={
                            value.activityInstanceIds
                        }
                        onBarcodeSearch={handleOnBarcodeSearch}
                    />
                )}
            </TableRow>

            {secondaryRows?.map((row, index) => (
                <TableRow key={index}>
                    <ReportTableMatrixDetailRow
                        columns={columns}
                        row={row}
                        selectedCustomListItemsNames={
                            selectedCustomListItemsNames
                        }
                        highlightedActivityInstanceIds={
                            value.activityInstanceIds
                        }
                        onBarcodeSearch={handleOnBarcodeSearch}
                    />
                </TableRow>
            ))}
        </React.Fragment>
    );
};

export default ReportTableRow;
