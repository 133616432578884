import { useMutation, useQuery, useQueryClient } from "react-query";

import axios from "axios";

import { IChecklistByIdRequest } from "./IChecklistByIdRequest";
import { IWorkChecklistDto } from "./IWorkChecklistDto";
import { convertToDate, getTimeZone } from "common/utils/time";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IDictionary from "common/viewModels/IDictionary";
import ITableSet from "http/ITableSet";
import IWorkFilter from "http/filters/IWorkFilter";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IFinalizeStepInstanceRequest from "http/requests/IFinalizeStepInstanceRequest";
import IPauseRequest from "http/requests/IPauseRequest";
import { IQuickRunRequest } from "http/requests/IQuickRunRequest";
import IWorkflowRunRequest, {
    IChangedStepInstance,
} from "http/requests/IWorkflowRunRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";
import IPauseResponse from "http/responses/IPauseResponse";
import IAttachment from "models/IAttachment";
import { IChecklistEntityDto } from "models/IChecklistEntityDto";
import IStepInstance from "models/IStepInstance";
import IActivityListDTO from "models/dto/IActivityListDTO";
import IFinalizeStepInstanceDTO from "models/dto/IFinalizeStepInstanceDTO";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";

const baseUrl = "/api/work";
const checklistBaseUrl = "/api/workflowRun";

const CHECKLIST_BY_ID_KEY = "checklist-id";
export const STEPINSTANCES_BY_CHECKLIST_ID_KEY =
    "step-instances-by-checklist-id";
export const STEPINSTACE_ACTIVITIES_BY_CHECKLIST_ID_KEY =
    "step-instance-activities-by-checklist-id";
const STEPINSTANCE_ACTIVITY_ATTACHEMENTS_BY_PARENT_ID_KEY =
    "step-instance-activity-attachments-by-parent-id";

export const CHECKLIST_LIST = "work-checklists";

export const useCanEditChecklist = (checklistId?: string) => {
    const url = `/api/workflowRun/can-edit/${checklistId}`;

    return useQuery(
        ["can-edit-checklist", checklistId],
        async (context) => {
            const response = await axios.get<boolean>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        {
            enabled: !!checklistId,
        },
    );
};

export const useWorkChecklists = (
    filter: IWorkFilter,
    refetchInterval?: number,
) => {
    const url = baseUrl;
    const queryClient = useQueryClient();

    return useQuery(
        [CHECKLIST_LIST, "all-home", filter],
        async (context) => {
            const response = await axios.post<IWorkChecklistDto>(url, filter, {
                signal: context.signal,
            });

            for (const value of Object.values(response.data.itemById)) {
                value.startDate = convertToDate(value.startDate);
                value.endDate = convertToDate(value.endDate);
                value.archivedAt = convertToDate(value.archivedAt);
                value.pausedAt = convertToDate(value.pausedAt);

                queryClient.setQueryData<IChecklistEntityDto>(
                    [CHECKLIST_BY_ID_KEY, value.id],
                    value,
                );
            }

            return response.data;
        },
        {
            refetchOnWindowFocus: false,
            refetchInterval,
        },
    );
};

export const useChecklistById = (
    enabled: boolean,
    checkIfAdmin: boolean,
    checklistId: string,
    refetchInterval?: number,
) => {
    const url = `${baseUrl}/checklist`;

    return useQuery(
        [CHECKLIST_BY_ID_KEY, checklistId],
        async (context) => {
            if (checklistId) {
                const request: IChecklistByIdRequest = {
                    id: checklistId,
                    checkIfAdmin,
                };

                const response = await axios.get<IChecklistEntityDto>(url, {
                    params: request,
                    signal: context.signal,
                });

                if (response.data) {
                    response.data.startDate = convertToDate(
                        response.data.startDate,
                    );

                    response.data.endDate = convertToDate(
                        response.data.endDate,
                    );

                    response.data.archivedAt = convertToDate(
                        response.data.archivedAt,
                    );
                    response.data.pausedAt = convertToDate(
                        response.data.pausedAt,
                    );
                    return response.data;
                }

                return undefined;
            }
        },
        {
            enabled,
            refetchInterval,
            isDataEqual: (oldData, newData) =>
                JSON.stringify(oldData || "") === JSON.stringify(newData || ""),
        },
    );
};

export const useStepInstancesByChecklistId = (
    enabled: boolean,
    checkIfAdmin: boolean,
    checklistId?: string,
    refetchInterval?: number,
) => {
    return useQuery(
        [STEPINSTANCES_BY_CHECKLIST_ID_KEY, checklistId],
        async (context) => {
            const url = `/api/stepinstance/checklist/${checklistId}`;

            const response = await axios.get<IDictionary<IStepInstance>>(url, {
                params: { checkIfAdmin },
                signal: context.signal,
            });

            for (const key in response.data) {
                response.data[key].completedAt = convertToDate(
                    response.data[key].completedAt,
                );
            }

            return {
                values: response.data,
                ids: Object.keys(response.data),
            } as ITableSet<IStepInstance>;
        },
        {
            enabled: enabled && Boolean(checklistId),
            refetchInterval,

            isDataEqual: (oldData, newData) =>
                JSON.stringify(oldData) === JSON.stringify(newData || ""),
        },
    );
};

export const useStepInstancesActivitiesByChecklistId = (
    enabled: boolean,
    checkIfAdmin: boolean,
    checklistId?: string,
    refetchInterval?: number,
) => {
    return useQuery(
        [STEPINSTACE_ACTIVITIES_BY_CHECKLIST_ID_KEY, checklistId],
        async (context) => {
            const url = `/api/activityInstance/checklist/${checklistId}`;

            const response = await axios.get<IActivityListDTO>(url, {
                params: { checkIfAdmin },
                signal: context.signal,
            });

            for (const key in response.data.activityInstances) {
                response.data.activityInstances[key].updatedAt = convertToDate(
                    response.data.activityInstances[key].updatedAt,
                );
            }

            return response.data;
        },
        {
            enabled: enabled && Boolean(checklistId),
            refetchInterval,
            isDataEqual: (oldData, newData) =>
                JSON.stringify(oldData) === JSON.stringify(newData),
        },
    );
};

export const useActivityInstanceAttachmentsByParentId = (
    isImprovement: boolean,
    parentId?: string,
) => {
    return useQuery(
        [STEPINSTANCE_ACTIVITY_ATTACHEMENTS_BY_PARENT_ID_KEY, parentId],
        async (context) => {
            if (parentId) {
                const url = `/api/attachment/by-parent-id/${parentId}`;

                const response = await axios.get<IDictionary<IAttachment[]>>(
                    url,
                    {
                        signal: context.signal,
                        params: { isImprovement },
                    },
                );

                return response.data;
            }
        },
        {
            enabled: Boolean(parentId),
        },
    );
};

async function updateChecklist(request: IWorkflowRunRequest) {
    request.timezone = getTimeZone();
    return await axios.put<IChecklistEntityDto>(checklistBaseUrl, request);
}
function updateChecklistValue(
    title?: string,
    startDate?: Date,
    endDate?: Date,
    checklist?: IChecklistEntityDto,
): IChecklistEntityDto {
    if (checklist) {
        return {
            ...checklist,
            title,
            startDate,
            endDate,
        };
    }
    return {
        id: "",
        isPausedBySchedule: false,
        isUpdated: false,
        status: ChecklistDynamicStatus.None,
        templateName: "",
        totalSteps: 0,
        finalizedSteps: 0,
        totalNotCompletedImprovementsCount: 0,
    };
}
function updateStepInstances(
    checkCanSeeAll?: boolean,
    changedStepInstances?: IChangedStepInstance[],
    oldStepInstances?: ITableSet<IStepInstance>,
    stepInstances?: ITableSet<IStepInstance>,
): ITableSet<IStepInstance> {
    if (stepInstances && changedStepInstances) {
        var hiddenStepIds: string[] = [];
        const updatedStepInstances = changedStepInstances.reduce<
            Record<string, IStepInstance | undefined>
        >((acc, value) => {
            if (value.removeEditor && !checkCanSeeAll) {
                hiddenStepIds = [...hiddenStepIds, value.id];
            }

            const stepInstanceValues = stepInstances.values[value.id];

            if (stepInstanceValues) {
                return {
                    ...acc,
                    [value.id]: {
                        ...stepInstanceValues,
                        teamIds: value.teamIds ?? stepInstanceValues.teamIds,
                        isTeamEveryone:
                            value.isTeamEveryone ??
                            stepInstanceValues.isTeamEveryone,
                    },
                };
            }

            return acc;
        }, {});

        return {
            ids: [
                ...stepInstances.ids.filter((id) => {
                    return hiddenStepIds.indexOf(id) < 0;
                }),
            ],
            values: {
                ...stepInstances.values,
                ...updatedStepInstances,
            },
        };
    } else if (oldStepInstances) {
        return oldStepInstances;
    }

    return {
        ids: stepInstances?.ids ?? [],
        values: stepInstances?.values ?? {},
    };
}
export const useUpdateChecklistMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (variables: { request: IWorkflowRunRequest }) =>
            updateChecklist(variables.request),
        {
            onMutate: async (variables) => {
                await queryClient.invalidateQueries([
                    CHECKLIST_BY_ID_KEY,
                    variables.request.id,
                ]);

                await queryClient.invalidateQueries([CHECKLIST_LIST]);

                const data = queryClient.getQueryData<IChecklistEntityDto>([
                    CHECKLIST_BY_ID_KEY,
                    variables.request.id,
                ]);

                const stepInstances = queryClient.getQueryData<
                    ITableSet<IStepInstance>
                >([STEPINSTANCES_BY_CHECKLIST_ID_KEY, variables.request.id]);

                const title = variables.request.name ? data?.title : undefined;
                const startDate = variables.request.startDate
                    ? data?.startDate
                    : undefined;
                const endDate = variables.request.endDate
                    ? data?.endDate
                    : undefined;

                const oldStepInstances = variables.request.changedStepInstances
                    ? stepInstances
                    : undefined;

                queryClient.setQueryData<IChecklistEntityDto>(
                    [CHECKLIST_BY_ID_KEY, variables.request.id],
                    (prev) =>
                        updateChecklistValue(
                            variables.request.name,
                            variables.request.startDate,
                            variables.request.endDate,
                            prev,
                        ),
                );

                if (variables.request.changedStepInstances) {
                    await queryClient.cancelQueries([
                        STEPINSTANCES_BY_CHECKLIST_ID_KEY,
                        variables.request.id,
                    ]);
                    queryClient.setQueryData<ITableSet<IStepInstance>>(
                        [
                            STEPINSTANCES_BY_CHECKLIST_ID_KEY,
                            variables.request.id,
                        ],
                        (prev) =>
                            updateStepInstances(
                                variables.request.checkIfAdmin,
                                variables.request.changedStepInstances,
                                undefined,
                                prev,
                            ),
                    );
                }

                return {
                    prevTitle: title,
                    prevStartDate: startDate,
                    prevEndDate: endDate,
                    prevStepInstances: oldStepInstances,
                };
            },
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries([CHECKLIST_LIST]);
            },
            onError: (
                err,
                variables,
                context:
                    | {
                          prevTitle?: string;
                          prevStartDate?: Date;
                          prevEndDate?: Date;
                          prevStepInstances?: ITableSet<IStepInstance>;
                      }
                    | undefined,
            ) => {
                if (context) {
                    queryClient.setQueryData<IChecklistEntityDto>(
                        [CHECKLIST_BY_ID_KEY, variables.request.id],
                        (prev) =>
                            updateChecklistValue(
                                context.prevTitle,
                                context.prevStartDate,
                                context.prevEndDate,
                                prev,
                            ),
                    );
                    if (variables.request.changedStepInstances) {
                        queryClient.setQueryData<ITableSet<IStepInstance>>(
                            [
                                STEPINSTANCES_BY_CHECKLIST_ID_KEY,
                                variables.request.id,
                            ],
                            (prev) =>
                                updateStepInstances(
                                    variables.request.checkIfAdmin,
                                    undefined,
                                    context.prevStepInstances,
                                    prev,
                                ),
                        );
                    }
                }
            },
        },
    );
};

function setChecklistPausedStatus(
    status?: ChecklistDynamicStatus,
    pausedAt?: Date,
    pausedByName?: string,
    checklist?: IChecklistEntityDto,
): IChecklistEntityDto {
    if (checklist && status) {
        return {
            ...checklist,
            status,
            pausedAt,
            pausedByName,
        };
    }
    return {
        id: "",
        isPausedBySchedule: false,
        isUpdated: false,
        status: ChecklistDynamicStatus.None,
        templateName: "",
        totalSteps: 0,
        finalizedSteps: 0,
        totalNotCompletedImprovementsCount: 0,
    };
}

async function updateChecklistPausedStatus(request: IPauseRequest) {
    const url = `${checklistBaseUrl}/pause`;

    try {
        const response = await axios.post<IPauseResponse>(url, request);

        return ExecutionResult.Result<IPauseResponse>(response.data);
    } catch (error) {
        return ExecutionResult.Failed<IPauseResponse>(error);
    }
}

export const usePauseChecklistMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (variables: { request: IPauseRequest }) =>
            updateChecklistPausedStatus(variables.request),
        {
            onSuccess: async (response) => {
                if (response.Succeeded && response.Data) {
                    await queryClient.invalidateQueries([
                        CHECKLIST_BY_ID_KEY,
                        response.Data.id,
                    ]);

                    await queryClient.invalidateQueries([CHECKLIST_LIST]);

                    queryClient.setQueryData<IChecklistEntityDto>(
                        [CHECKLIST_BY_ID_KEY, response.Data.id],
                        (prev) =>
                            setChecklistPausedStatus(
                                response.Data?.status,
                                response.Data?.pausedAt,
                                response.Data?.name,
                                prev,
                            ),
                    );
                }
            },
        },
    );
};

export const useUnfinalizeStepMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: { request: IFinalizeStepInstanceRequest }) => {
            const url = `/api/stepinstance/unfinalize`;

            try {
                const response = await axios.post<IFinalizeStepInstanceDTO>(
                    url,
                    variables.request,
                );

                return ExecutionResult.Result<IFinalizeStepInstanceDTO>(
                    response.data,
                );
            } catch (error) {
                return ExecutionResult.Failed<IFinalizeStepInstanceDTO>(error);
            }
        },
        {
            onSuccess: async (data, variables) => {
                await queryClient.invalidateQueries([CHECKLIST_LIST]);
            },
        },
    );
};

async function updateChecklistArchiveStatus(request: IArchiveRequest) {
    const url = `${checklistBaseUrl}/archive`;

    try {
        const response = await axios.post<IArchiveResponse>(url, request);

        return ExecutionResult.Result<IArchiveResponse>(response.data);
    } catch (error) {
        return ExecutionResult.Failed<IArchiveResponse>(error);
    }
}

export const useArchiveChecklistMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (variables: { request: IArchiveRequest }) =>
            updateChecklistArchiveStatus(variables.request),
        {
            onSuccess: async (response) => {
                if (response.Succeeded && response.Data) {
                    await queryClient.invalidateQueries([
                        CHECKLIST_BY_ID_KEY,
                        response.Data.id,
                    ]);

                    await queryClient.invalidateQueries([CHECKLIST_LIST]);

                    queryClient.setQueryData<IChecklistEntityDto>(
                        [CHECKLIST_BY_ID_KEY, response.Data.id],
                        (prev) =>
                            setChecklistArchivedStatus(
                                response.Data?.status,
                                response.Data?.archivedAt,
                                response.Data?.name,
                                prev,
                            ),
                    );
                }
            },
        },
    );
};
function setChecklistArchivedStatus(
    status?: ChecklistDynamicStatus,
    archivedAt?: Date,
    archivedByName?: string,
    checklist?: IChecklistEntityDto,
): IChecklistEntityDto {
    if (checklist && status) {
        return {
            ...checklist,
            status,
            archivedAt,
            archivedByName,
        };
    }
    return {
        id: "",
        isPausedBySchedule: false,
        isUpdated: false,
        status: ChecklistDynamicStatus.None,
        templateName: "",
        totalSteps: 0,
        finalizedSteps: 0,
        totalNotCompletedImprovementsCount: 0,
    };
}

async function deleteChecklistById(id: string) {
    try {
        await axios.delete(`${checklistBaseUrl}/${id}`);

        return true;
    } catch (ex) {}

    return false;
}

export const useDeleteChecklistMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: { id: string }) => {
            deleteChecklistById(variables.id);
        },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries([
                    CHECKLIST_BY_ID_KEY,
                    variables.id,
                ]);
                queryClient.invalidateQueries([CHECKLIST_LIST]);
            },
        },
    );
};

async function updateRunChecklist(request: IQuickRunRequest) {
    const url = `${checklistBaseUrl}/quick-run`;
    request.timezone = getTimeZone();

    try {
        const response = await axios.post<string>(url, request);

        return ExecutionResult.Result<string>(response.data);
    } catch (error) {
        return ExecutionResult.Failed<string>(error);
    }
}

export const useRunChecklist = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (variables: { request: IQuickRunRequest }) =>
            updateRunChecklist(variables.request),
        {
            onSuccess: async (response) => {
                if (response.Succeeded && response.Data) {
                    await queryClient.invalidateQueries([
                        CHECKLIST_BY_ID_KEY,
                        response.Data,
                    ]);
                    await queryClient.invalidateQueries([CHECKLIST_LIST]);
                }
            },
        },
    );
};
