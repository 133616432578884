import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { HeaderTableCell } from "./header-table-cell/HeaderTableCell";
import { IHeaderTableCellProps } from "./header-table-cell/IHeaderTableCellProps";
import { TableCell } from "common/components/table";
import { ITemplateReportActivityTitle } from "components/template-checklist-report/api/ITemplateReportActivity";

interface IProps
    extends Pick<IHeaderTableCellProps, "onFilter" | "customListFilters"> {
    columns: ITemplateReportActivityTitle[];

    onExpand: (mustExpand: boolean, id: string) => void;
}

export const LevelTwoReportDetailsHeader = (props: IProps) => {
    const { columns, customListFilters } = props;

    const { t } = useTranslation();

    return (
        <React.Fragment>
            {columns.map((column) => {
                if (column?.isStatusColumn) {
                    return (
                        <TableCell key={column.id} isHighlighted>
                            {t(k.STATUS)}
                        </TableCell>
                    );
                }

                return (
                    <HeaderTableCell
                        key={column?.id}
                        column={column ?? null}
                        customListFilters={customListFilters}
                        onFilter={props.onFilter}
                        onExpand={props.onExpand}
                    />
                );
            })}
        </React.Fragment>
    );
};
