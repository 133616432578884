import React from "react";

import ReportTableCell from "../report-table/ReportTableCell";
import SeparateTablesCell from "../report-table/SeparateTablesCell";
import { TableMatrixRowType } from "./api/TableMatrix";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import StepInstanceStatusBadge from "common/components/badges/step-instance-status-badge/StepInstanceStatusBadge";
import { TableCell } from "common/components/table";
import IDictionary from "common/viewModels/IDictionary";
import { ITemplateReportActivityTitle } from "components/template-checklist-report/api/ITemplateReportActivity";

interface IProps {
    columns: Array<ITemplateReportActivityTitle | undefined>;
    row: TableMatrixRowType | null;

    selectedCustomListItemsNames?: Record<
        string,
        IValueLabelItemWithState | undefined
    >;
    highlightedActivityInstanceIds: IDictionary<boolean>;

    onBarcodeSearch: (barcode: string) => void;
}

export const ReportTableMatrixDetailRow = (props: IProps) => {
    const {
        columns,
        row,
        selectedCustomListItemsNames,
        highlightedActivityInstanceIds,
    } = props;

    return (
        <React.Fragment>
            <SeparateTablesCell />

            {row?.cellValues.map((cellValue, index) => {
                if (cellValue.isHidden) {
                    return;
                }

                const { stepInstanceStatus, rowSpan, value, isCellExpanded } =
                    cellValue;

                const { isStatusColumn, expandInfo } = columns[index] ?? {};

                if (isStatusColumn) {
                    return (
                        <TableCell key={index} isHighlighted rowSpan={rowSpan}>
                            {stepInstanceStatus ? (
                                <StepInstanceStatusBadge
                                    status={stepInstanceStatus}
                                />
                            ) : (
                                <ReportTableCell.NotAvailableMessage />
                            )}
                        </TableCell>
                    );
                }

                return (
                    <ReportTableCell
                        key={index}
                        value={value ?? null}
                        rowSpan={rowSpan}
                        isCellExpanded={isCellExpanded}
                        selectedCustomListItemsNames={
                            selectedCustomListItemsNames
                        }
                        highlightedActivityInstanceIds={
                            highlightedActivityInstanceIds
                        }
                        isTopLevel={expandInfo?.isTopLevel ?? false}
                        onBarcodeSearch={props.onBarcodeSearch}
                    />
                );
            })}
        </React.Fragment>
    );
};
