import { v4 as uuidv4 } from "uuid";

import { IChecklistReportExpandableColumnRequest } from "./IChecklistReportExpandableColumnRequest";
import { IChecklistReportExpandableColumnResponse } from "./IChecklistReportExpandableColumnResponse";
import IChecklistReportResponse from "components/template-checklist-report/api/IChecklistReportResponse";
import {
    ChecklistReportValueColumnType,
    IChecklistReportValueSumFilter,
} from "components/template-checklist-report/api/IChecklistReportValueSumFilter";
import {
    ITemplateReportActivity,
    ITemplateReportActivityTitle,
} from "components/template-checklist-report/api/ITemplateReportActivity";
import { TemplateReportValuesByColumnKeyIdType } from "components/template-checklist-report/api/TemplateReportValuesByColumnKeyIdType";
import { ActivityType } from "models/enums/ActivityType";
import { StepInstanceStatus } from "models/enums/StepInstanceStatus";

type TableMatrixCellType = {
    isHidden?: boolean;
    rowSpan?: number;
    isCellExpanded?: boolean;

    value?: ITemplateReportActivity;
    stepInstanceStatus?: StepInstanceStatus;
};

export type TableMatrixRowType = {
    cellValues: TableMatrixCellType[];
};

export type TableMatrixValuesType = {
    isInitialized?: boolean;

    stepInstanceIds: string[];
    colSpanByStepInstanceId: Record<string, number | undefined>;
    labelByStepInstanceId: Record<string, string | undefined>;

    columns: ITemplateReportActivityTitle[];

    valueColumns: Pick<
        IChecklistReportValueSumFilter,
        "hasValueColumn" | "columns"
    >;

    rowSpans: Record<string, number | undefined>;

    primaryRowsByChecklistId: Record<string, TableMatrixRowType | undefined>;
    secondaryRowsByChecklistId: Record<
        string,
        TableMatrixRowType[] | undefined
    >;

    defaultTotalColSpan: number | null;

    uniqueSelectedCustomListItemIds: Array<{ key: string; value?: Date }>;
};

const emptyValue: TableMatrixValuesType = {
    stepInstanceIds: [],
    colSpanByStepInstanceId: {},
    labelByStepInstanceId: {},
    columns: [],
    valueColumns: {
        hasValueColumn: false,
        columns: [],
    },
    rowSpans: {},
    primaryRowsByChecklistId: {},
    secondaryRowsByChecklistId: {},
    defaultTotalColSpan: null,
    uniqueSelectedCustomListItemIds: [],
};

export class TableMatrix {
    private _isInitialized: boolean = false;

    private initialValue: TableMatrixValuesType = emptyValue;
    private value: TableMatrixValuesType = emptyValue;

    getValues = () => {
        return { ...this.value };
    };

    private checklistIds: string[] = [];

    trySetupValue = (
        reportDetails: IChecklistReportResponse,
        checklistIds: string[],
    ) => {
        if (!this._isInitialized) {
            this.checklistIds = checklistIds;

            this.setInitialValue(reportDetails, checklistIds);

            this.value = this.cloneInitialValue();

            return true;
        }

        return false;
    };

    private setInitialValue = (
        reportDetails: IChecklistReportResponse,
        checklistIds: string[],
    ) => {
        const {
            labelByStepInstanceId,
            stepInstanceIds,
            totalColSpan: defaultTotalColSpan,
            colSpanByStepInstanceId,
        } = reportDetails.header;

        const primaryRowsByChecklistId = this.getPrimaryRowsByChecklistId(
            checklistIds,
            reportDetails,
        );

        const columns = this.getInitialColumns(reportDetails);
        const valueColumns = this.getValueColumns(columns);

        const value: TableMatrixValuesType = {
            stepInstanceIds,
            colSpanByStepInstanceId,
            labelByStepInstanceId,
            columns,
            valueColumns,
            rowSpans: {},
            primaryRowsByChecklistId,
            secondaryRowsByChecklistId: {},
            defaultTotalColSpan,
            uniqueSelectedCustomListItemIds:
                reportDetails.uniqueSelectedCustomListItemIds,
        };

        this.initialValue = value;
    };

    private cloneInitialValue() {
        const value = this.initialValue;

        const nextPrimaryRowsByChecklistId: Record<
            string,
            TableMatrixRowType | undefined
        > = {};

        Object.keys(value.primaryRowsByChecklistId).reduce((acc, key) => {
            const prevCellValues =
                value.primaryRowsByChecklistId[key]?.cellValues;

            if (prevCellValues) {
                acc[key] = {
                    cellValues: prevCellValues.map((x) => ({ ...x })),
                };
            }

            return acc;
        }, nextPrimaryRowsByChecklistId);

        const result: TableMatrixValuesType = {
            isInitialized: true,
            stepInstanceIds: [...value.stepInstanceIds],
            colSpanByStepInstanceId: { ...value.colSpanByStepInstanceId },
            labelByStepInstanceId: { ...value.labelByStepInstanceId },
            columns: [...value.columns],
            valueColumns: {
                hasValueColumn: value.valueColumns.hasValueColumn,
                columns: [...value.valueColumns.columns],
            },
            rowSpans: {},
            primaryRowsByChecklistId: nextPrimaryRowsByChecklistId,
            secondaryRowsByChecklistId: {},
            defaultTotalColSpan: value.defaultTotalColSpan,
            uniqueSelectedCustomListItemIds: [
                ...value.uniqueSelectedCustomListItemIds,
            ],
        };

        return result;
    }

    private updateUniqueSelectedCustomListItemIds(
        newUniqueSelectedCustomListItemIds: Array<{
            key: string;
            value?: Date;
        }>,
    ) {
        let allUniqueIds: typeof newUniqueSelectedCustomListItemIds;

        if (this.value.uniqueSelectedCustomListItemIds) {
            allUniqueIds = newUniqueSelectedCustomListItemIds.concat(
                this.value.uniqueSelectedCustomListItemIds,
            );
        } else {
            allUniqueIds = newUniqueSelectedCustomListItemIds.concat(
                this.initialValue.uniqueSelectedCustomListItemIds,
            );
        }

        const uniqueByKeyMap = new Map(allUniqueIds.map((x) => [x.key, x]));

        this.value.uniqueSelectedCustomListItemIds = Array.from(
            uniqueByKeyMap.values(),
        );
    }

    private getPrimaryRowsByChecklistId(
        checklistIds: string[],
        reportDetails: IChecklistReportResponse | null,
    ) {
        const result: Record<string, TableMatrixRowType | undefined> = {};

        const { stepInstanceIds = [], activitiesByStepInstanceId = {} } =
            reportDetails?.header ?? {};

        checklistIds.reduce<typeof result>((acc, checklistId) => {
            const cellValues: TableMatrixCellType[] = [];

            const valuesByStepInstanceId =
                reportDetails?.valuesByChecklistId[checklistId]
                    ?.valuesByStepInstanceId;

            for (const stepInstanceId of stepInstanceIds) {
                const {
                    valuesByActivityVersionId: valuesByColumnKeyId,
                    status: stepInstanceStatus,
                } = valuesByStepInstanceId?.[stepInstanceId] ?? {};

                const statusCellValue: TableMatrixCellType = {
                    stepInstanceStatus,
                };

                cellValues.push(statusCellValue);

                const activitiesColumns =
                    activitiesByStepInstanceId[stepInstanceId] ?? [];

                for (const { columnKeyId } of activitiesColumns) {
                    const cellValue: TableMatrixCellType = {
                        value: valuesByColumnKeyId?.[columnKeyId],
                    };

                    cellValues.push(cellValue);
                }
            }

            const rowValues: TableMatrixRowType = {
                cellValues,
            };

            acc[checklistId] = rowValues;

            return acc;
        }, result);

        return result;
    }

    private getNewRowCellValues(
        newColumns: ITemplateReportActivityTitle[],
        rowSpan: number,
        columnValuesByColumnKeyId?: TemplateReportValuesByColumnKeyIdType,
    ) {
        const addedCellValues = newColumns.reduce<TableMatrixCellType[]>(
            (acc, column) => {
                const cellValue: TableMatrixCellType = {
                    value: columnValuesByColumnKeyId?.[column.columnKeyId],
                    isHidden: false,
                };

                if (rowSpan > 1) {
                    cellValue.rowSpan = rowSpan;
                }

                acc.push(cellValue);

                return acc;
            },
            [],
        );

        return addedCellValues;
    }

    private findLeastCommonMultiple(arr: number[]) {
        arr = arr.sort((a, b) => a - b);

        let num = arr[0];

        for (let i = 1; i < arr.length; i++) {
            let j = 1;

            while (num % arr[i] !== 0) {
                j = j + 1;

                num = j * arr[0];
            }

            arr[0] = num;
        }

        return num;
    }

    /* {tableMatrixData
    ? undefined
    : reportDetails?.header.headerLevelOneStepInstanceIds.map(
            (headerStepInstanceId, headerStepInstanceIndex) => {
                const headerLevelTwoColumns =
                    reportDetails.header
                        .headerLevelTwoActivitiesByHeaderLevelOneStepInstanceId[
                        headerStepInstanceId
                    ];

                const cellValue =
                    reportDetails.valuesByChecklistId[
                        checklistId
                    ];

                if (cellValue === undefined) {
                    return (
                        <React.Fragment
                            key={headerStepInstanceId}
                        >
                            <SeparateTablesCell />

                            <TableCell isHighlighted>
                                <ReportTableCell.NotAvailableMessage />
                            </TableCell>

                            {headerLevelTwoColumns.map((x) => (
                                <TableCell key={x.id}>
                                    <ReportTableCell.NotAvailableMessage />
                                </TableCell>
                            ))}
                        </React.Fragment>
                    );
                }

                const valuesByActivityVersionId =
                    cellValue
                        .valuesByActivityVersionIdByHeaderLevelOneStepInstanceId[
                        headerStepInstanceId
                    ];

                const stepInstanceId =
                    cellValue.stepInstanceIds[
                        headerStepInstanceIndex
                    ];

                const stepInstance = stepInstanceId
                    ? reportDetails.stepInstancesById[
                        stepInstanceId
                    ]
                    : undefined;

                return (
                    <React.Fragment key={headerStepInstanceId}>
                        {headerStepInstanceIndex === 0 && (
                            <SeparateTablesCell />
                        )}

                        <TableCell isHighlighted>
                            {stepInstance ? (
                                <StepInstanceStatusBadge
                                    status={stepInstance.param}
                                />
                            ) : (
                                <ReportTableCell.NotAvailableMessage />
                            )}
                        </TableCell>

                        {headerLevelTwoColumns.map(
                            ({ id, columnKeyId }) => (
                                <ReportTableCell
                                    key={id}
                                    value={
                                        valuesByActivityVersionId[
                                            columnKeyId
                                        ] ?? null
                                    }
                                    selectedCustomListItemsNames={
                                        selectedCustomListItemsNames
                                    }
                                    highlightedActivityInstanceIds={
                                        value.activityInstanceIds
                                    }
                                    isTopLevel={true}
                                    onBarcodeSearch={
                                        handleOnBarcodeSearch
                                    }
                                />
                            ),
                        )}
                    </React.Fragment>
                );
            },
        )} */

    private updateValuesOnExpand(
        columnIndex: number,
        response: IChecklistReportExpandableColumnResponse,
    ) {
        const checklistIds = this.checklistIds;

        const result: Record<string, TableMatrixRowType[]> = {};

        for (const checklistId of checklistIds) {
            const rows = this.getAllRowsByChecklistId(checklistId);

            const allRowIds = response.rowIdsByChecklistId[checklistId] ?? [];

            const maxRowIdsCount = Math.max(
                ...allRowIds.map((x) => (x.length === 0 ? 1 : x.length)),
            );

            const expandableColumnRowSpan =
                rows[0].cellValues[columnIndex].rowSpan ?? 1;

            const leastCommonMultiple = this.findLeastCommonMultiple([
                maxRowIdsCount,
                expandableColumnRowSpan,
            ]);

            const prevTotalRowsCount = rows.length;

            const nextTotalRowsCount = leastCommonMultiple * allRowIds.length;

            const shouldAddNewRows = nextTotalRowsCount > prevTotalRowsCount;

            if (shouldAddNewRows) {
                const diff = nextTotalRowsCount - prevTotalRowsCount;

                for (let i = 0; i < diff; i++) {
                    const newRow = this.getNewEmptyRow(checklistId);

                    rows.push(newRow);
                }

                this.value.rowSpans[checklistId] = rows.length;

                const columnsCount =
                    this.value.primaryRowsByChecklistId[checklistId]?.cellValues
                        .length ?? 0;

                const rowSpanMutliplier =
                    leastCommonMultiple / expandableColumnRowSpan;

                for (let i = 0; i < prevTotalRowsCount; i++) {
                    const rowIndex = prevTotalRowsCount - 1 - i;

                    for (let j = 0; j < columnsCount; j++) {
                        const cell = rows[rowIndex].cellValues[j];

                        if (cell.isHidden) {
                            continue;
                        }

                        const prevRowSpan = cell.rowSpan ?? 1;

                        const nextRowSpan = prevRowSpan * rowSpanMutliplier;

                        cell.rowSpan =
                            nextRowSpan > 1 ? nextRowSpan : undefined;

                        if (rowIndex > 0) {
                            // relocate cell values

                            const newRowIndex = rowSpanMutliplier * rowIndex;

                            const emptyCell = rows[newRowIndex].cellValues[j];

                            rows[newRowIndex].cellValues[j] = cell;

                            rows[rowIndex].cellValues[j] = emptyCell;
                        }
                    }
                }
            }

            // add empty cells to the right of the expanded column

            for (const row of rows) {
                row.cellValues[columnIndex].isCellExpanded = true;

                const emptyCellValues =
                    response.columns.map<TableMatrixCellType>(() => ({
                        isHidden: true,
                    }));

                row.cellValues.splice(columnIndex + 1, 0, ...emptyCellValues);
            }

            // assign actual new cell values

            const rowIdsWithMaxWidth: Array<string | null>[] = [];

            for (let i = 0; i < allRowIds.length; i++) {
                const emptyRowIdsDiffCount =
                    maxRowIdsCount > allRowIds[i].length
                        ? maxRowIdsCount - allRowIds[i].length
                        : 0;

                const rowIds = Array.from(Array(emptyRowIdsDiffCount).keys())
                    .map<string | null>((x) => null)
                    .concat(allRowIds[i].map<string | null>((id) => id));

                rowIdsWithMaxWidth.push(rowIds);
            }

            let rowIndex: number = 0;

            const rowSpan = leastCommonMultiple / maxRowIdsCount;

            for (const rowIds of rowIdsWithMaxWidth) {
                for (const rowId of rowIds) {
                    const columnValuesByColumnKeyId = rowId
                        ? response.columnValuesByRowId[rowId]
                        : undefined;

                    const addedCellValues = this.getNewRowCellValues(
                        response.columns,
                        rowSpan,
                        columnValuesByColumnKeyId,
                    );

                    const row = rows[rowIndex];

                    row.cellValues.splice(
                        columnIndex + 1,
                        addedCellValues.length,
                    );

                    row.cellValues.splice(
                        columnIndex + 1,
                        0,
                        ...addedCellValues,
                    );

                    rowIndex += rowSpan;
                }
            }

            result[checklistId] = rows;
        }

        this.bindRows(result);
    }

    private getNewEmptyRow(checklistId: string) {
        const row = {
            checklistId,
            cellValues: this.value.columns.map<TableMatrixCellType>(() => ({
                isHidden: true,
            })),
        };

        return row;
    }

    private updateColumnsCountByStepInstanceId() {
        this.value.colSpanByStepInstanceId = this.value.columns.reduce<
            Record<string, number | undefined>
        >((acc, column) => {
            const { stepInstanceId } = column?.expandInfo ?? {};

            if (stepInstanceId) {
                acc[stepInstanceId] = (acc[stepInstanceId] ?? 0) + 1;
            }

            return acc;
        }, {});
    }

    private getStatusColumn(expandInfo: {
        level: number;
        stepInstanceId: string;
        isTopLevel?: boolean;
    }) {
        const result: ITemplateReportActivityTitle = {
            id: uuidv4(),
            title: "",
            type: ActivityType.Value,
            columnKeyId: "",
            isStatusColumn: true,

            expandInfo,
        };

        return result;
    }

    expand = (
        id: string,
        response: IChecklistReportExpandableColumnResponse | null,
    ) => {
        const columnIndex = this.value.columns.findIndex((x) => x?.id === id);

        const column = this.value.columns[columnIndex];

        const addedColumns =
            response?.columns.map((item) => {
                const newColumn: ITemplateReportActivityTitle = {
                    ...item,

                    expandInfo: {
                        level: (column?.expandInfo?.level ?? 0) + 1,
                        parentCustomListId: column?.customListId,
                        stepInstanceId:
                            column?.expandInfo?.stepInstanceId ?? "",

                        columnKeyIdsPath: (
                            column?.expandInfo?.columnKeyIdsPath ?? []
                        ).concat(column?.columnKeyId ?? ""),
                    },
                };

                return newColumn;
            }) ?? [];

        if (column?.expandInfo) {
            this.value.columns.splice(columnIndex + 1, 0, ...addedColumns);

            this.value.valueColumns = this.getValueColumns(this.value.columns);

            column.expandInfo.isExpanded = true;
            column.expandInfo.level = column.expandInfo.level + 1;

            this.updateColumnsCountByStepInstanceId();

            if (response) {
                this.updateValuesOnExpand(columnIndex, response);

                this.updateUniqueSelectedCustomListItemIds(
                    response.uniqueSelectedCustomListItemIds ?? [],
                );
            }
        }
    };

    collapse = (id: string) => {
        const columnIndex = this.value.columns.findIndex((x) => x?.id === id);

        const column = this.value.columns[columnIndex];

        const { level: columnLevel } = column?.expandInfo ?? {
            level: 0,
        };

        const stopIndex = this.value.columns.findIndex((x, index) => {
            const { level, isExpanded } = x?.expandInfo ?? { level: 0 };

            return (
                index > columnIndex &&
                (level < columnLevel || (level === columnLevel && isExpanded))
            );
        });

        const columnsLength = this.value.columns.length;

        const deleteCount =
            (stopIndex === -1 ? columnsLength - 1 : stopIndex - 1) -
            columnIndex;

        if (column?.expandInfo) {
            this.value.columns.splice(columnIndex + 1, deleteCount);

            this.value.valueColumns = this.getValueColumns(this.value.columns);

            column.expandInfo.isExpanded = false;
            column.expandInfo.level = column.expandInfo.level - 1;

            this.updateColumnsCountByStepInstanceId();

            this.tryResetRows(columnIndex, deleteCount);
        }
    };

    private tryResetRows(columnIndex: number, deleteCount: number) {
        const anyExpandedColumn = this.value.columns.some(
            (x) => x?.expandInfo?.isExpanded,
        );

        const checklistIds = this.checklistIds;

        if (anyExpandedColumn) {
            for (const checklistId of checklistIds) {
                const rows = this.getAllRowsByChecklistId(checklistId);

                for (const row of rows) {
                    if (columnIndex < row.cellValues.length) {
                        row.cellValues[columnIndex].isCellExpanded = false;
                    }

                    row.cellValues.splice(columnIndex + 1, deleteCount);
                }
            }
        } else {
            this.value = this.cloneInitialValue();
        }
    }

    private getAllRowsByChecklistId(checklistId: string) {
        const primaryRow = this.value.primaryRowsByChecklistId[checklistId];

        const secondaryRows =
            this.value.secondaryRowsByChecklistId[checklistId] ?? [];

        if (primaryRow) {
            return [primaryRow].concat(secondaryRows);
        }

        return secondaryRows;
    }

    private bindRows(
        rowsByChecklistId: Record<string, TableMatrixRowType[] | undefined>,
    ) {
        const checklistIds = this.checklistIds;

        for (const checklistId of checklistIds) {
            const rows = rowsByChecklistId[checklistId];

            if (rows && rows.length > 0) {
                this.value.primaryRowsByChecklistId[checklistId] = rows[0];

                this.value.secondaryRowsByChecklistId[checklistId] =
                    rows.slice(1);
            }
        }
    }

    getRequest = (id: string) => {
        const columnIndex = this.value.columns.findIndex((x) => x?.id === id);

        const column = this.value.columns[columnIndex];

        const { customListId, expandInfo } = column ?? {};

        if (customListId) {
            const { isTopLevel, parentCustomListId } = expandInfo ?? {};

            const rowIdsByChecklistId: Record<string, Array<string[]>> = {};

            this.checklistIds.reduce<typeof rowIdsByChecklistId>(
                (acc, checklistId) => {
                    const rows = this.getAllRowsByChecklistId(checklistId);

                    const selectedIds: Array<string[]> = [];

                    for (const row of rows) {
                        const cellValue = row.cellValues[columnIndex];

                        if (cellValue.isHidden) {
                            continue;
                        }

                        const selectedCustomListItemIds =
                            cellValue.value?.values?.map((x) => x.value) ?? [];

                        selectedIds.push(selectedCustomListItemIds);
                    }

                    acc[checklistId] = selectedIds;

                    return acc;
                },
                rowIdsByChecklistId,
            );

            const request: IChecklistReportExpandableColumnRequest = {
                customListId,
                rowIdsByChecklistId,
                parentCustomListId: isTopLevel ? undefined : parentCustomListId,
            };

            return request;
        }
    };

    // return (
    //     <React.Fragment>
    //         {headerLevelOneStepInstanceIds.map((stepInstanceId) => (
    //             <React.Fragment key={stepInstanceId}>
    //                 <TableCell isHighlighted>{t(k.STATUS)}</TableCell>

    //                 {headerLevelTwoActivitiesByHeaderLevelOneStepInstanceId[
    //                     stepInstanceId
    //                 ].map((column) => (
    //                     <HeaderTableCell
    //                         key={column.id}
    //                         column={column}
    //                         customListFilters={customListFilters}
    //                         onFilter={props.onFilter}
    //                         onExpand={handleExpand}
    //                     />
    //                 ))}
    //             </React.Fragment>
    //         ))}
    //     </React.Fragment>
    // );

    private getInitialColumns(reportDetails: IChecklistReportResponse | null) {
        const { stepInstanceIds = [], activitiesByStepInstanceId = {} } =
            reportDetails?.header ?? {};

        const result = stepInstanceIds.flatMap((stepInstanceId) => {
            const columns =
                activitiesByStepInstanceId[stepInstanceId].map((activity) => {
                    const result: ITemplateReportActivityTitle = {
                        ...activity,

                        expandInfo: {
                            level: 0,
                            isTopLevel: true,
                            stepInstanceId,
                        },
                    };

                    return result;
                }) ?? [];

            const statusColumn = this.getStatusColumn({
                level: 0,
                isTopLevel: true,
                stepInstanceId,
            });

            return [statusColumn].concat(columns);
        });

        return result;
    }

    private getValueColumns = (columns: ITemplateReportActivityTitle[]) => {
        const result = columns.reduce<
            Pick<IChecklistReportValueSumFilter, "hasValueColumn" | "columns">
        >(
            (acc, el) => {
                if (el) {
                    const newItem: ChecklistReportValueColumnType = {
                        id: el.id,
                        type: el.type,
                        isStatusColumn: el.isStatusColumn ?? false,
                        columnKeyId: null,
                        path: null,
                        isActivity: el.expandInfo?.isTopLevel ?? false,
                    };

                    acc.columns.push(newItem);

                    if (
                        el.type === ActivityType.Value &&
                        newItem.isStatusColumn === false
                    ) {
                        acc.hasValueColumn = true;

                        newItem.columnKeyId = el.columnKeyId;
                        newItem.path = el.expandInfo?.columnKeyIdsPath ?? null;
                    }
                }

                return acc;
            },
            {
                hasValueColumn: false,
                columns: [],
            },
        );

        return result;
    };
}
