import { useQuery, useQueryClient } from "react-query";
import { useMutation } from "react-query";

import axios from "axios";

import IResetDraftResponse from "./IResetDraftResponse";
import ITemplateFilter from "./ITemplateFilter";
import ITemplateRowDTO, { ITemplateRowDetails } from "./ITemplateRowDTO";
import { IUploadedFile } from "./IUploadedFile";
import { IValueLabelItem } from "common/IValueLabelItem";
import { convertToDate } from "common/utils/time";
import ExecutionResult from "common/viewModels/ExecutionResult";
import { TEMPLATE_DRAFT_CONFIG_BY_VERSION_ID } from "components/improvement-forms/api/hooks";
import { STEPS_DRAFT_BY_TEMPLATE_VERSION_ID } from "components/steps/api/hooks";
import IPaginatedList from "http/IPaginatedList";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IWorkflowRequest from "http/requests/IWorkflowRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";
import IWorkflowUpgradeResponse from "http/responses/IWorkflowUpgradeResponse";
import { IChecklistEntityDto } from "models/IChecklistEntityDto";
import IWorkflow from "models/IWorkflow";
import IWorkflowDTO from "models/dto/IWorkflowDTO";
import { TemplateType } from "models/enums/TemplateType";

const baseUrl = "/api/template";

export const useCanEditTemplate = (templateVersionId?: string) => {
    const url = `${baseUrl}/can-edit/${templateVersionId}`;

    return useQuery(
        ["can-edit-template", templateVersionId],
        async (context) => {
            const response = await axios.get<boolean>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        {
            enabled: !!templateVersionId,
        },
    );
};

export const getCanEditTemplate = async (templateVersionId?: string) => {
    const url = `${baseUrl}/can-edit/${templateVersionId}`;

    const response = await axios.get<boolean>(url);

    return response.data;
};

export const useTemplateOptionById = (id?: string, createNew?: boolean) => {
    return useQuery(
        ["published-template-option", id, createNew],
        async (context) => {
            if (id) {
                if (createNew) {
                    const url = `${baseUrl}/published-option-by-version-id/${id}`;

                    const response = await axios.get<
                        IValueLabelItem<string, string, string>
                    >(url, {
                        signal: context.signal,
                    });

                    return response.data;
                } else {
                    const url = `${baseUrl}/published-option/${id}`;

                    const response = await axios.get<
                        IValueLabelItem<string, string, string>
                    >(url, {
                        signal: context.signal,
                    });

                    return response.data;
                }
            }
        },
    );
};

export const useTemplateOptions = () => {
    const url = `${baseUrl}/published-options`;

    return useQuery(["template-published-options"], async (context) => {
        const response = await axios.get<
            IValueLabelItem<string, string, string>[]
        >(url, {
            signal: context.signal,
        });

        return response.data;
    });
};

export const usePublishedTemplates = () => {
    const url = `${baseUrl}/published`;

    return useQuery(["published-templates"], async (context) => {
        const response = await axios.get<IWorkflow[]>(url, {
            signal: context.signal,
        });

        return response.data;
    });
};

export const usePublishedChecklistTemplates = (customListItemId?: string) => {
    const url = customListItemId
        ? `${baseUrl}/published/checklist?customListItemId=${customListItemId}`
        : `${baseUrl}/published/checklist`;

    return useQuery(
        ["published-templates", "checklist-templates"],
        async (context) => {
            const response = await axios.get<IWorkflow[]>(url, {
                signal: context.signal,
            });

            return response.data;
        },
    );
};

export const usePublishedChecklistTemplatesOptions = () => {
    const url = `${baseUrl}/published-list`;

    return useQuery(
        ["published-templates-options", "checklist-templates"],
        async (context) => {
            const response = await axios.get<IValueLabelItem<string, string>[]>(
                url,
                {
                    signal: context.signal,
                },
            );

            return response.data;
        },
    );
};

export const usePublishedImprovementForms = (customListItemId?: string) => {
    const url = customListItemId
        ? `${baseUrl}/published/improvement?customListItemId=${customListItemId}`
        : `${baseUrl}/published/improvement`;

    return useQuery(
        ["published-templates", "improvement-forms"],
        async (context) => {
            const response = await axios.get<IWorkflow[]>(url, {
                signal: context.signal,
            });

            return response.data;
        },
    );
};

export const getPublishedTemplates = (args: {
    type?: TemplateType;
    customListItemId?: string;
}) => {
    switch (args.type) {
        case TemplateType.Checklist: {
            return usePublishedChecklistTemplates(args.customListItemId);
        }

        case TemplateType.Improvement: {
            return usePublishedImprovementForms(args.customListItemId);
        }

        default: {
            return usePublishedTemplates();
        }
    }
};

export const TEMPLATE_BY_VERSION_ID = "template-by-version-id";
export const TEMPLATE_DRAFT_BY_VERSION_ID = "template-draft-by-version-id";
export const CHECKLIST_TEMPLATES_LIST = "checklist-templates-list";
export const IMPROVEMENT_TEMPLATES_LIST = "improvement-templates-list";

export const useMutateTemplate = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: {
            request: IWorkflowRequest;
            isCreate: boolean;
            invalidateSteps?: boolean;
        }) => {
            try {
                const { request, isCreate } = variables;

                const response = await axios<IWorkflowDTO>({
                    method: isCreate ? "POST" : "PUT",
                    url: baseUrl,
                    data: request,
                });

                return ExecutionResult.Result<IWorkflowDTO>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<IWorkflowDTO>(error);
            }
        },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries([
                    TEMPLATE_DRAFT_BY_VERSION_ID,
                    variables.request.workflowVersionId,
                ]);

                queryClient.invalidateQueries([
                    TEMPLATE_DRAFT_CONFIG_BY_VERSION_ID,
                    variables.request.workflowVersionId,
                ]);

                if (variables.invalidateSteps) {
                    queryClient.invalidateQueries([
                        STEPS_DRAFT_BY_TEMPLATE_VERSION_ID,
                        variables.request.workflowVersionId,
                    ]);
                }
            },
        },
    );
};

export const useDeleteTemplateByVersionId = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: { versionId: string; filter?: string }) => {
            try {
                const response = await axios.delete(
                    `${baseUrl}/${variables.versionId}`,
                );

                return ExecutionResult.Result(response.data);
            } catch (error) {
                return ExecutionResult.Failed(error);
            }
        },
        {
            onSuccess: async (data, variables) => {
                queryClient.removeQueries([
                    TEMPLATE_DRAFT_BY_VERSION_ID,
                    variables.versionId,
                ]);
            },
        },
    );
};

export const useDeleteTemplateDraftByVersionId = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: { versionId: string; filter?: string }) => {
            try {
                const response = await axios.post<IResetDraftResponse>(
                    `${baseUrl}/reset-draft/${variables.versionId}`,
                );

                return ExecutionResult.Result<IResetDraftResponse>(
                    response.data,
                );
            } catch (error) {
                return ExecutionResult.Failed<IResetDraftResponse>(error);
            }
        },
        {
            onSuccess: async (data, variables) => {
                queryClient.invalidateQueries([
                    TEMPLATE_DRAFT_BY_VERSION_ID,
                    variables.versionId,
                ]);

                queryClient.invalidateQueries([
                    TEMPLATE_DRAFT_CONFIG_BY_VERSION_ID,
                    variables.versionId,
                ]);

                queryClient.invalidateQueries([
                    STEPS_DRAFT_BY_TEMPLATE_VERSION_ID,
                    variables.versionId,
                ]);
            },
        },
    );
};

export const useArchiveTemplateByVersionId = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: { request: IArchiveRequest }) => {
            try {
                const response = await axios.post<IArchiveResponse>(
                    `${baseUrl}/archive`,
                    variables.request,
                );

                return ExecutionResult.Result<IArchiveResponse>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<IArchiveResponse>(error);
            }
        },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries([
                    TEMPLATE_DRAFT_BY_VERSION_ID,
                    variables.request.id,
                ]);
            },
        },
    );
};

const updateTemplateVersion = async (updatedTemplateVersion: IWorkflow) => {
    const response = await axios.post<IWorkflow>(
        baseUrl,
        updatedTemplateVersion,
    );

    return response.data;
};

export const useTemplateByVersionId = (
    templateVersionId?: string,
    enable = true,
) => {
    const url = `${baseUrl}/template-by-version-id/${templateVersionId}`;

    return useQuery(
        [TEMPLATE_BY_VERSION_ID, templateVersionId],
        async (context) => {
            const response = await axios.get<IWorkflow>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        { enabled: enable && !!templateVersionId },
    );
};
export const useTemplateDraftByVersionId = (
    templateVersionId?: string,
    enabled?: boolean,
) => {
    const url = `${baseUrl}/draft/${templateVersionId}`;

    return useQuery(
        [TEMPLATE_DRAFT_BY_VERSION_ID, templateVersionId],
        async (context) => {
            const response = await axios.get<IWorkflow>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        { enabled: !!templateVersionId && enabled },
    );
};

export const useTemplateByChecklistId = (checklistId?: string) => {
    const url = `${baseUrl}/by-workflow-run/${checklistId}`;

    return useQuery(
        ["template-by-checklist-id", checklistId],
        async (context) => {
            const response = await axios.get<IWorkflow>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        { enabled: !!checklistId },
    );
};

export const useAllFilteredTemplates = (
    filter: ITemplateFilter,
    enabled = true,
) => {
    const isImprovementForm = filter.type === TemplateType.Improvement;

    const key = isImprovementForm
        ? IMPROVEMENT_TEMPLATES_LIST
        : CHECKLIST_TEMPLATES_LIST;

    return useQuery(
        [key, filter],
        async (context) => {
            const response = await axios.post<IPaginatedList<ITemplateRowDTO>>(
                `${baseUrl}/list`,
                filter,
                {
                    signal: context.signal,
                },
            );
            return response.data;
        },
        { enabled },
    );
};

export const fetchTemplateDetailsById = async (
    id: string,
    signal?: AbortSignal,
) => {
    const url = `${baseUrl}/template-row-details/${id}`;

    const response = await axios.get<ITemplateRowDetails>(url, { signal });

    return response.data;
};

export const getNotUpgradableChecklists = (templateVersionId?: string) => {
    const url = `/api/workflowRun/not-upgradable/${templateVersionId}`;

    return useQuery(
        ["not-upgradable-checklists", templateVersionId],
        async (context) => {
            const response = await axios.get<IChecklistEntityDto[]>(url, {
                signal: context.signal,
            });

            const result = response.data;

            for (const item of result) {
                item.completedAt = convertToDate(item.completedAt);

                item.startDate = convertToDate(item.startDate);
                item.endDate = convertToDate(item.endDate);

                item.pausedAt = convertToDate(item.pausedAt);
                item.archivedAt = convertToDate(item.archivedAt);
            }

            return result;
        },
        { enabled: !!templateVersionId },
    );
};

export const useTemplateUpgrade = async (workflowVersionId: string) => {
    const url = `${baseUrl}/upgrade-workflows/${workflowVersionId}`;

    const response = await axios.get<IWorkflowUpgradeResponse>(url);

    return response.data;
};

export const useUploadedFilesByIds = async (ids: string[]) => {
    const url = `/api/picture/by-ids`;

    const response = await axios.post<IUploadedFile[]>(url, ids);

    return response.data;
};
