import { useMutation, useQuery, useQueryClient } from "react-query";

import axios from "axios";

import { IGetUser } from "./IGetUsersDTO";
import { IGetUsersRequest } from "./IGetUsersRequest";
import IUserFilter from "./IUserFilter";
import IUserRequest from "./IUserRequest";
import IUserRowDTO from "./IUserRowDTO";
import { IValueLabelItem } from "common/IValueLabelItem";
import { IValueLabelList } from "common/IValueLabelList";
import { convertToDate } from "common/utils/time";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IDictionary from "common/viewModels/IDictionary";
import IUserDTO from "components/users/api/IUserDTO";
import IPaginatedList from "http/IPaginatedList";
import {
    ITableSetWithOptions,
    ITableSetWithValueLabelOptions,
} from "http/ITableSetWithOptions";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";

const baseUrl = "/api/user";

export const USER_LIST = "user-list";

export const usePaginatedUsersList = (filter: IUserFilter) => {
    const url = `${baseUrl}/list`;

    return useQuery([USER_LIST, filter], async (context) => {
        const response = await axios.post<IPaginatedList<IUserRowDTO>>(
            url,
            filter,
            { signal: context.signal },
        );

        const ids = response.data.items.map((x) => x.id);
        const values = response.data.items.reduce<IDictionary<IUserRowDTO>>(
            (prev, cur) => {
                prev[cur.id] = cur;

                return prev;
            },
            {},
        );

        return { ids, values, totalCount: response.data.totalCount };
    });
};

export const USER_BY_ID = "user-by-id";

export const useUserById = (id?: string) => {
    const url = `${baseUrl}/${id}`;

    return useQuery(
        [USER_BY_ID, id],
        async (context) => {
            const response = await axios.get<IUserRowDTO>(url, {
                signal: context.signal,
            });

            if (response.data.userPositions) {
                for (const position of response.data.userPositions) {
                    position.startDate = convertToDate(position.startDate);
                    position.endDate = position.endDate
                        ? convertToDate(position.endDate)
                        : null;
                }
            }

            return response.data;
        },
        { enabled: Boolean(id), refetchOnWindowFocus: false },
    );
};

const USERS_BY_IDS = "users-by-ids";

export const useUserListByIds = (filter?: string[]) => {
    const url = `${baseUrl}/users-by-ids`;

    return useQuery(
        [USERS_BY_IDS, filter],
        async (context) => {
            const response = await axios.post<IUserDTO[]>(url, filter, {
                signal: context.signal,
            });

            return response.data;
        },
        { enabled: filter ? filter.length > 0 : false },
    );
};

export const GET_USER_LIST = "get-user-list";

export const useUserList = (enabled?: boolean) => {
    const url = `${baseUrl}/published-extended`;

    return useQuery(
        [GET_USER_LIST],
        async (context) => {
            const response = await axios.get<IUserDTO[]>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        { enabled },
    );
};

export const PUBLISHED_USERS = "users-published";

export const usePublishedUserOptions = (enabled?: boolean) => {
    const url = `${baseUrl}/published`;

    return useQuery(
        [PUBLISHED_USERS],
        async (context) => {
            const response = await axios.get<IValueLabelItem<string, string>[]>(
                url,
                {
                    signal: context.signal,
                },
            );

            const values = response.data.reduce<
                IDictionary<IValueLabelItem<string, string>>
            >((map, val) => {
                map[val.value] = val;

                return map;
            }, {});

            const result: ITableSetWithValueLabelOptions<
                string,
                undefined,
                string
            > = {
                ids: response.data.map((x) => x.value),
                values,
                options: response.data,
            };

            return result;
        },
        { enabled: enabled ?? true },
    );
};

export const USERS_FOR_IMPORT = "for-import";

export const useUsersForImport = () => {
    const url = `${baseUrl}/for-import`;

    return useQuery([USERS_FOR_IMPORT], async (context) => {
        const response = await axios.get<
            IValueLabelItem<string, string, string>[]
        >(url, {
            signal: context.signal,
        });

        return response.data;
    });
};

export const PUBLISHED_USERS_EXTENDED = "published-users-extended";

export const usePublishedUserOptionsExtended = () => {
    const url = `${baseUrl}/published-extended`;

    return useQuery([PUBLISHED_USERS_EXTENDED], async (context) => {
        const response = await axios.get<IUserDTO[]>(url, {
            signal: context.signal,
        });

        const values = response.data.reduce<IDictionary<IUserDTO>>(
            (map, val) => {
                map[val.value] = val;
                return map;
            },
            {},
        );

        const result: ITableSetWithOptions<IUserDTO> = {
            ids: response.data.map((x) => x.value),
            values,
            options: response.data,
        };

        return result;
    });
};

export const REPORTING_USERS_BY_ID = "reporting-users-by-id";

export const useReportingUsersById = (id?: string) => {
    const url = `${baseUrl}/reporting-users-by-id/${id}`;

    return useQuery(
        [REPORTING_USERS_BY_ID, id],
        async (context) => {
            const response = await axios.get<IValueLabelItem<string, string>[]>(
                url,
                {
                    signal: context.signal,
                },
            );

            const values = response.data.reduce<
                IDictionary<IValueLabelItem<string, string>>
            >((map, val) => {
                map[val.value] = val;

                return map;
            }, {});

            const result: ITableSetWithValueLabelOptions<
                string,
                undefined,
                string
            > = {
                ids: response.data.map((x) => x.value),
                values,
                options: response.data,
            };

            return result;
        },
        { enabled: Boolean(id) },
    );
};

export async function importUserMutation(data: IUserRequest[]) {
    const url = `${baseUrl}/import`;

    try {
        const response = await axios.post<number>(url, data);

        return ExecutionResult.Result(response.data);
    } catch (error) {
        return ExecutionResult.Failed<number>(error);
    }
}

export const usePublishedByTeamId = (id?: string, enabled?: boolean) => {
    const url = id
        ? `${baseUrl}/published-by-team/${id}`
        : `${baseUrl}/published-by-team`;

    return useQuery<IValueLabelList<string, string>>(
        ["publishedByTeam", id],
        async () => {
            const response =
                await axios.get<IValueLabelList<string, string>>(url);
            return response.data;
        },
        { enabled: enabled ?? true },
    );
};

export const useGetUsers = (request: IGetUsersRequest, enabled = true) => {
    const url = `${baseUrl}/get-users`;

    return useQuery(
        [`get-users`, request],
        async () => {
            const response = await axios.post<IGetUser[]>(url, request);

            return response.data;
        },
        { enabled },
    );
};

export const useSaveUserMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: { isCreate: boolean; value: IUserRequest }) => {
            let result: string;

            const data = {
                ...variables.value,
                userPositions: variables.value.userPositions?.map((item) => ({
                    ...item,
                    startDate: item.startDate
                        ? item.startDate?.toDateString()
                        : undefined,
                    endDate: item.endDate
                        ? item.endDate?.toDateString()
                        : undefined,
                })),
            };

            if (variables.isCreate) {
                const response = await axios.post<string>(baseUrl, data);

                result = response.data;
            } else {
                const response = await axios.put<string>(baseUrl, data);

                result = response.data;
            }

            return result;
        },
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([USER_BY_ID, data]);
            },
        },
    );
};

export const useArchiveUserMutation = () => {
    const queryClient = useQueryClient();
    const url = `${baseUrl}/archive`;
    return useMutation(
        async (variables: { request: IArchiveRequest }) => {
            const response = await axios.post<IArchiveResponse>(
                url,
                variables.request,
            );

            return response.data;
        },
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([USER_BY_ID, data.id]);
            },
        },
    );
};
