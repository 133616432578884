import React from "react";

import { TableMatrixValuesType } from "./api/TableMatrix";
import { TableCell } from "common/components/table";

interface IProps {
    tableMatrixData: TableMatrixValuesType;
}

export const LevelOneReportDetailsHeader = (props: IProps) => {
    const { stepInstanceIds, colSpanByStepInstanceId, labelByStepInstanceId } =
        props.tableMatrixData;

    return (
        <React.Fragment>
            {stepInstanceIds.map((id) => (
                <TableCell
                    key={id}
                    isHighlighted
                    colSpan={colSpanByStepInstanceId?.[id]}
                    className="top-level-header"
                >
                    {labelByStepInstanceId[id]}
                </TableCell>
            ))}
        </React.Fragment>
    );
};
