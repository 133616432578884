import "./ReportTable.scss";

import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { connect } from "react-redux";

import { format } from "date-fns";

import k from "i18n/keys";

import { LevelOneReportDetailsHeader } from "../report-table-details/LevelOneReportDetailsHeader";
import { LevelTwoReportDetailsHeader } from "../report-table-details/LevelTwoReportDetailsHeader";
import {
    TableMatrix,
    TableMatrixValuesType,
} from "../report-table-details/api/TableMatrix";
import { fetchExpandedColumnValuesAsync } from "../report-table-details/api/hooks";
import { ChecklistStatusFilter } from "../report-table-details/filters/ChecklistStatusFilter";
import { getInitialSelectedStatuses } from "../report-table-details/filters/filterUtils";
import ReportTableRow from "./ReportTableRow";
import { ReportTableSumCells } from "./ReportTableSumCells";
import SeparateTablesCell from "./SeparateTablesCell";
import IChanged from "common/IChanged";
import { InfoIcon } from "common/components/icons";
import ArchiveWorksheetMessage from "common/components/messages/ArchiveWorksheetMessage";
import DeleteWorksheetMessage from "common/components/messages/DeleteWorksheetMessage";
import PauseWorksheetMessage from "common/components/messages/PauseWorksheetMessage";
import ResumeWorksheetMessage from "common/components/messages/ResumeWorksheetMessage";
import UnarchiveWorksheetMessage from "common/components/messages/UnarchiveWorksheetMessage";
import Pagination from "common/components/pagination/Pagination";
import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHeader,
    TableRow,
} from "common/components/table";
import { MenuCommands } from "common/components/toggle-state/MenuCommands";
import { useCustomListItemNamesByIds } from "components/custom-list-item-page/api/hooks";
import { ChecklistReportSortBy } from "components/template-checklist-report/api/ChecklistReportSortBy";
import IChecklistReportDTO from "components/template-checklist-report/api/IChecklistReportDTO";
import IChecklistReportFilter, {
    CustomListFilterType,
} from "components/template-checklist-report/api/IChecklistReportFilter";
import IChecklistReportRow from "components/template-checklist-report/api/IChecklistReportRow";
import { IChecklistReportValueSumFilter } from "components/template-checklist-report/api/IChecklistReportValueSumFilter";
import { ITemplateReportActivityTitle } from "components/template-checklist-report/api/ITemplateReportActivity";
import {
    getCalculateValueSums,
    useArchiveChecklistMutation,
    useChecklistReportDetailsQuery,
    useChecklistReportItemsQuery,
    useDeleteByIdChecklistMutation,
    usePauseChecklistMutation,
} from "components/template-checklist-report/api/hooks";
import ISortOrder from "http/filters/ISortOrder";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IPauseRequest from "http/requests/IPauseRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";
import IPauseResponse from "http/responses/IPauseResponse";
import {
    ChecklistDynamicStatus,
    allChecklistStatuses,
} from "models/enums/ChecklistDynamicStatus";
import { showConfirm } from "store/confirms/actions";

const DEFAULT_PAGE_SIZE = 10;

const WORKSHEET_REPORT_COLSPAN = 7;

type DateFilterKeysType = keyof Pick<
    IChecklistReportFilter,
    "startDate" | "endDate"
>;

interface IOwnProps {
    templateVersionId?: string;

    isAdministrator: boolean;

    filter: IChanged<IChecklistReportFilter>;

    syncData?: boolean;

    reverseOrderNumber: number;

    onBarcodeSearch?: (barcode: string) => void;

    onShowChecklistDetails: (checklistId: string) => void;

    onShowRecurrence: (recurringId: string) => void;

    onShowImprovements: (improvementId: string) => void;
}

interface IDispatchProps {
    showConfirm: (message: React.ReactNode) => Promise<boolean | undefined>;
}

type Props = IOwnProps & IDispatchProps;

const ReportTable = (props: Props) => {
    const {
        isAdministrator,
        templateVersionId,
        filter: externalFilter,
        syncData,
        reverseOrderNumber,
    } = props;

    const { t, i18n } = useTranslation();

    const queryClient = useQueryClient();

    const [visibleMenuParentCellZIndex, setVisibleMenuParentCellZIndex] =
        useState<Partial<Record<keyof IChecklistReportFilter, number>>>({});

    const [activePage, setActivePage] = useState(1);

    const tableMatrix = React.useRef(new TableMatrix()).current;

    const [tableMatrixValue, setTableMatrixValue] =
        useState<TableMatrixValuesType>(tableMatrix.getValues());

    const handleExpandHeader = async (mustExpand: boolean, id: string) => {
        if (mustExpand) {
            const request = tableMatrix.getRequest(id);

            try {
                if (request) {
                    const expandColumnResponse =
                        await fetchExpandedColumnValuesAsync(request);

                    tableMatrix.expand(id, expandColumnResponse);
                }
            } catch {}
        } else {
            tableMatrix.collapse(id);
        }

        setTableMatrixValue(tableMatrix.getValues());
    };

    const [requestFilter, setRequestFilter] = useState<IChecklistReportFilter>({
        status: getInitialSelectedStatuses(),
    });

    const handleSetRequestFilter = (
        value: React.SetStateAction<IChecklistReportFilter>,
    ) => {
        //setTableMatrix(undefined);
        console.log("set table matrix to undefined...");

        setRequestFilter(value);
    };

    const [prevExternalFilter, setPrevExternalFilter] =
        useState<IChecklistReportFilter>();

    const [sort, setSort] = useState<ISortOrder<ChecklistReportSortBy>>({
        sortBy: ChecklistReportSortBy.StartDate,
        sortAsc: false,
    });

    const {
        data: checklistReportDto,
        isLoading,
        refetch: refetchReportItems,
    } = useChecklistReportItemsQuery(
        { syncData },
        externalFilter.value,
        requestFilter,
    );

    const showMatchResultsColumn = checklistReportDto?.hasSearchResult;

    const checklistRows = checklistReportDto?.paginatedData.items ?? [];

    const { data: reportDetails } = useChecklistReportDetailsQuery(
        { syncData },
        checklistReportDto?.uniqueKeys ?? {},
        templateVersionId ? checklistReportDto?.checklistIds : undefined,
    );

    React.useEffect(() => {
        if (reportDetails && checklistReportDto?.checklistIds) {
            if (
                tableMatrix.trySetupValue(
                    reportDetails,
                    checklistReportDto.checklistIds,
                )
            ) {
                setTableMatrixValue(tableMatrix.getValues());
            }
        }
    }, [reportDetails, checklistReportDto?.checklistIds]);

    const { data: selectedCustomListItemsNames } = useCustomListItemNamesByIds(
        tableMatrixValue.uniqueSelectedCustomListItemIds,
    );

    const fetchData = async (
        pageNumber: number,
        newSort?: ISortOrder<ChecklistReportSortBy>,
    ) => {
        const newRequestFilter: IChecklistReportFilter = {
            page: pageNumber,
            pageSize: DEFAULT_PAGE_SIZE,

            sortBy: sort.sortBy,
            sortAsc: sort.sortAsc,

            ...externalFilter.value,

            status: requestFilter.status,

            ...newSort,

            templateVersionIds: templateVersionId
                ? [templateVersionId]
                : externalFilter.value.templateVersionIds,
        };

        handleSetRequestFilter(newRequestFilter);
    };

    const onFilterChange = () => {
        const page = 1;

        setActivePage(page);

        fetchData(page);
    };

    const valueSumFilter = React.useMemo<IChecklistReportValueSumFilter>(() => {
        const { hasValueColumn, columns } = tableMatrixValue.valueColumns;

        const result: IChecklistReportValueSumFilter = {
            filter: requestFilter,
            hasValueColumn,
            columns,
        };

        return result;
    }, [tableMatrixValue.valueColumns, requestFilter]);

    const sumOfValuesResponse = useQuery(
        ["calc-value-sum", valueSumFilter.columns],
        ({ signal }) => getCalculateValueSums(valueSumFilter, signal),
        {
            enabled: valueSumFilter.hasValueColumn,
        },
    );

    useEffect(() => {
        if (
            prevExternalFilter === undefined ||
            (prevExternalFilter &&
                (prevExternalFilter.keywordSearch !==
                    externalFilter.value.keywordSearch ||
                    ((externalFilter.value.splitSearchResult ?? false) ===
                        false &&
                        prevExternalFilter.templateVersionIds !==
                            externalFilter.value.templateVersionIds) ||
                    prevExternalFilter.status !== externalFilter.value.status ||
                    prevExternalFilter.startDate !==
                        externalFilter.value.startDate ||
                    prevExternalFilter.endDate !==
                        externalFilter.value.endDate))
        ) {
            onFilterChange();
        }

        setPrevExternalFilter(externalFilter.value);
    }, [externalFilter]);

    const refreshListWhenItemRemoved = () => {
        if (checklistRows.length === 1 && activePage > 1) {
            const pageNumber = activePage - 1;

            setActivePage(pageNumber);

            fetchData(pageNumber);
        } else {
            refetchReportItems();
        }
    };

    const onStatusUpdated = (
        id: string,
        status: ChecklistDynamicStatus | undefined,
    ) => {
        updateItem((x) =>
            x.id === id
                ? {
                      ...x,
                      status: status ?? x.status,
                  }
                : x,
        );
    };

    const updateItem = (
        updater: (x: IChecklistReportRow) => IChecklistReportRow,
    ) => {
        queryClient.setQueryData<IChecklistReportDTO>(
            ["useChecklistReportItems", requestFilter],
            (prev) => {
                let result: IChecklistReportDTO;

                if (prev) {
                    result = {
                        ...prev,
                        paginatedData: {
                            ...prev.paginatedData,
                            items: prev.paginatedData.items.map(updater),
                        },
                    };
                } else {
                    result = {
                        totalOnlyStoppableChecklistsCount: 0,
                        totalCompletedImprovementsCount: 0,
                        totalNotCompletedImprovementsCount: 0,
                        paginatedData: { items: [], totalCount: 0 },

                        hasSearchResult: false,
                        checklistIds: [],
                        uniqueKeys: {},
                    };
                }

                return result;
            },
        );
    };

    const onArchived = (response?: IArchiveResponse) => {
        if (response) {
            onStatusUpdated(response.id, response.status);

            const isArchived =
                response.status === ChecklistDynamicStatus.Archived;

            if (isArchived && response.isNotVisible) {
                refreshListWhenItemRemoved();
            }
        }
    };

    const archiveMutation = useArchiveChecklistMutation();

    const handleUnarchive = async (checklistId: string) => {
        const confirmOk = await props.showConfirm(
            <UnarchiveWorksheetMessage />,
        );

        if (confirmOk) {
            const request: IArchiveRequest = {
                id: checklistId,
                isArchive: false,
                isArchivedVisible: true,
            };

            const response = await archiveMutation.mutateAsync(request);

            onArchived(response);
        }
    };

    const handleArchive = async (checklistId: string) => {
        const confirmOk = await props.showConfirm(<ArchiveWorksheetMessage />);

        if (confirmOk) {
            const request: IArchiveRequest = {
                id: checklistId,
                isArchive: true,
                isArchivedVisible:
                    requestFilter.status?.includes(
                        ChecklistDynamicStatus.Archived,
                    ) ?? false,
            };

            const response = await archiveMutation.mutateAsync(request);

            onArchived(response);
        }
    };

    const onPause = (response?: IPauseResponse) => {
        if (response) {
            onStatusUpdated(response.id, response.status);
        }

        queryClient.invalidateQueries("useTotalChecklistsCountToPause");
    };

    const pauseMutation = usePauseChecklistMutation();

    const handleResume = async (checklistId: string) => {
        const confirmOk = await props.showConfirm(<ResumeWorksheetMessage />);

        if (confirmOk) {
            const request: IPauseRequest = {
                id: checklistId,
                isPause: false,
            };

            const response = await pauseMutation.mutateAsync(request);

            onPause(response);
        }
    };

    const handleStop = async (checklistId: string) => {
        const confirmOk = await props.showConfirm(<PauseWorksheetMessage />);

        if (confirmOk) {
            const request: IPauseRequest = {
                id: checklistId,
                isPause: true,
            };

            const response = await pauseMutation.mutateAsync(request);

            onPause(response);
        }
    };

    const deleteMutation = useDeleteByIdChecklistMutation();

    const handleDelete = async (checklistId: string) => {
        const confirmOk = await props.showConfirm(<DeleteWorksheetMessage />);

        if (confirmOk) {
            const result = await deleteMutation.mutateAsync(checklistId);

            if (result) {
                refreshListWhenItemRemoved();
            }
        }
    };

    const handlePreview = (checklistId: string) => {
        props.onShowChecklistDetails(checklistId);
    };

    const handlePreviewRecurrence = (checklistId: string) => {
        const checklist = checklistRows.find((x) => x.id === checklistId);

        if (checklist) {
            const reccurenceId = checklist.scheduleId;

            if (reccurenceId) {
                props.onShowRecurrence(reccurenceId);
            }
        }
    };

    const handleActionSelect = (checklistId: string, command: MenuCommands) => {
        switch (command) {
            case MenuCommands.Preview:
                handlePreview(checklistId);

                break;

            case MenuCommands.PreviewRecurrence:
                handlePreviewRecurrence(checklistId);

                break;

            case MenuCommands.Stop:
                handleStop(checklistId);

                break;

            case MenuCommands.UndoStop:
                handleResume(checklistId);

                break;

            case MenuCommands.Archive:
                handleArchive(checklistId);

                break;

            case MenuCommands.UndoArchive:
                handleUnarchive(checklistId);

                break;

            case MenuCommands.Delete:
                handleDelete(checklistId);

                break;

            case MenuCommands.PreviewDeviations:
                props.onShowImprovements(checklistId);

                break;

            case MenuCommands.BarcodeSearch:
                if (props.onBarcodeSearch) {
                    const barcode = checklistId;

                    props.onBarcodeSearch(barcode);
                }

                break;

            default:
                break;
        }
    };

    const handleSort = (
        e: React.UIEvent,
        isBusy?: boolean,
        name?: string | number,
    ) => {
        if (isBusy) {
            return;
        }

        if (typeof name === "number") {
            const newSortAsc = sort.sortBy === name ? !sort.sortAsc : true;

            const newSort: ISortOrder<ChecklistReportSortBy> = {
                sortBy: name,
                sortAsc: newSortAsc,
            };

            setSort(newSort);

            fetchData(activePage, newSort);
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setActivePage(pageNumber);

        fetchData(pageNumber);
    };

    const handleOnShowFilterMenu =
        (key: keyof IChecklistReportFilter) => (showNext: boolean) => {
            const nextZIndex = showNext ? 100 + reverseOrderNumber : undefined;

            setVisibleMenuParentCellZIndex((prev) => ({
                ...prev,
                [key]: nextZIndex,
            }));
        };

    const [dateFilterLabels, setDateFilterLabels] = useState<
        Partial<Record<DateFilterKeysType, string>>
    >({});

    const handleFilterDate =
        (keyName: DateFilterKeysType) => (nextDate?: Date) => {
            const page = 1;

            setActivePage(page);

            handleSetRequestFilter((prev) => ({
                ...prev,
                [keyName]: nextDate,
                page,
            }));

            setDateFilterLabels((prev) => ({
                ...prev,
                [keyName]: nextDate ? format(nextDate, "P") : undefined,
            }));
        };

    const handleFilterByStatus = (ids: ChecklistDynamicStatus[]) => {
        const page = 1;

        setActivePage(page);

        handleSetRequestFilter((prev) => ({
            ...prev,
            status: ids,
            page,
        }));
    };

    const handleFilterByCustomLists = (
        column: ITemplateReportActivityTitle,
        filterValues: CustomListFilterType,
    ) => {
        const page = 1;

        setActivePage(page);

        handleSetRequestFilter((prev) => {
            const nextCustomListFilters = { ...prev.customListFilters };

            const nextCustomListFilterColumnKeyIdsPath = {
                ...prev.customListFilterColumnKeyIdsPath,
            };

            if (filterValues.isReset) {
                delete nextCustomListFilters[column.columnKeyId];

                delete nextCustomListFilterColumnKeyIdsPath[column.columnKeyId];
            } else {
                nextCustomListFilters[column.columnKeyId] = filterValues;

                const isTopLevel = column.expandInfo
                    ? (column.expandInfo.isTopLevel ?? false)
                    : true;

                if (isTopLevel === false) {
                    const columnKeyIdsPath =
                        column.expandInfo?.columnKeyIdsPath ?? [];

                    nextCustomListFilterColumnKeyIdsPath[column.columnKeyId] =
                        columnKeyIdsPath;
                }
            }

            //if (tableMatrix?.columns) {

            const filterKeys = Object.keys(nextCustomListFilters);

            for (const columnKeyId of filterKeys) {
                const columnIsFound = tableMatrixValue.columns.some(
                    (x) => x?.columnKeyId === columnKeyId,
                );

                if (!columnIsFound) {
                    delete nextCustomListFilters[columnKeyId];

                    delete nextCustomListFilterColumnKeyIdsPath[columnKeyId];
                }
            }
            //}

            return {
                ...prev,

                page,

                customListFilters: nextCustomListFilters,

                customListFilterColumnKeyIdsPath:
                    nextCustomListFilterColumnKeyIdsPath,
            };
        });
    };

    const statusFilterLabel = useMemo(() => {
        const selectedValues = requestFilter.status ?? [];

        const allDynamicStatuses = allChecklistStatuses.filter(
            (x) => x.id !== ChecklistDynamicStatus.None,
        );

        const defaultSelectedValuesLen = isAdministrator
            ? allDynamicStatuses.length
            : allDynamicStatuses.length - 1;

        if (
            selectedValues.length === 0 ||
            selectedValues.length === defaultSelectedValuesLen
        ) {
            return t(k.SHOW_ALL);
        }

        const len = allDynamicStatuses.length - 2;

        if (
            len === selectedValues.length &&
            selectedValues.includes(ChecklistDynamicStatus.Archived) ===
                false &&
            selectedValues.includes(ChecklistDynamicStatus.Scheduled) === false
        ) {
            if (isAdministrator) {
                return t(k.SHOWING_ALL_EXCEPT_ARCHIVED_OR_SCHEDULED);
            } else {
                return t(k.SHOWING_ALL_EXCEPT_SCHEDULED);
            }
        }

        const result = allDynamicStatuses
            .filter((x) => selectedValues.includes(x.id))
            .map((x) => t(x.label));

        const lastSelected = result.pop();

        if (result.length === 0) {
            return `${t(k.SHOWING)} ${lastSelected}`;
        }

        return `${t(k.SHOWING)} ${result.join(", ")} ${t(
            k.AND_,
        )} ${lastSelected}`;
    }, [requestFilter.status, i18n.language]);

    // const resetCustomListFilters = () => {
    //     handleSetRequestFilter((prev) => ({
    //         ...prev,
    //         customListFilters: undefined,
    //         customListFilterColumnKeyIdsPath: undefined,
    //     }));
    // };

    return (
        <div className="template-checklists-report">
            <Table
                className="template-checklists-report--table"
                isLoading={isLoading}
                defaultShowLoader={true}
                name="checklists-report-table"
                resizable={!(externalFilter.value.splitSearchResult ?? false)}
                recordsCount={checklistRows.length}
                hasFilters={externalFilter.isChanged}
                columnsCount={
                    tableMatrixValue.isInitialized
                        ? WORKSHEET_REPORT_COLSPAN +
                          (showMatchResultsColumn ? 1 : 0) +
                          tableMatrixValue.columns.length +
                          1
                        : undefined
                }
                emptyMessage={
                    <React.Fragment>
                        <h3>{t(k.ITS_EMPTY_HERE)}</h3>
                        {t(k.NO_AVAILABLE_CHECKLISTS_FOUND_FOR_YOUR_USER_ROLE)}
                    </React.Fragment>
                }
                noResultsMessage={
                    <React.Fragment>
                        <h3>{t(k.NO_RESULTS)}</h3>

                        {t(
                            k.CREATE_A_NEW_CHECKLIST_OR_TRY_DIFFERENT_FILTER_KEYWORDS,
                        )}
                    </React.Fragment>
                }
                pagination={
                    <Pagination
                        isBusy={isLoading}
                        activePage={activePage}
                        pageSize={DEFAULT_PAGE_SIZE}
                        totalCount={
                            checklistReportDto?.paginatedData.totalCount
                        }
                        onChange={handlePageChange}
                    />
                }
            >
                <TableHeader>
                    {tableMatrixValue.isInitialized && (
                        <TableRow fullHeight>
                            <TableCell
                                colSpan={
                                    WORKSHEET_REPORT_COLSPAN +
                                    (showMatchResultsColumn ? 1 : 0)
                                }
                                isHighlighted={true}
                                className="top-level-header table-cell__truncate"
                            >
                                {t(k.INFO)}
                            </TableCell>

                            <SeparateTablesCell />

                            <LevelOneReportDetailsHeader
                                tableMatrixData={tableMatrixValue}
                            />
                        </TableRow>
                    )}

                    <TableRow fullHeight>
                        <TableCell
                            name={ChecklistReportSortBy.Name}
                            isHighlighted={reportDetails !== undefined}
                            onClick={handleSort}
                            className="table-cell__sticky"
                            sortingBy={sort.sortBy}
                            sortingIsAsc={sort.sortAsc}
                        >
                            {t(k.CHECKLIST)}
                        </TableCell>

                        {showMatchResultsColumn && (
                            <TableCell>{t(k.RESULTS)}</TableCell>
                        )}

                        {tableMatrixValue.isInitialized ? undefined : (
                            <TableCell
                                name={ChecklistReportSortBy.WorkflowName}
                                onClick={handleSort}
                                className="column-lg"
                                sortingBy={sort.sortBy}
                                sortingIsAsc={sort.sortAsc}
                            >
                                {t(k.TEMPLATE)}
                            </TableCell>
                        )}

                        <TableCell
                            name={ChecklistReportSortBy.StartDate}
                            onClick={handleSort}
                            sortingBy={sort.sortBy}
                            sortingIsAsc={sort.sortAsc}
                            zIndex={visibleMenuParentCellZIndex["startDate"]}
                            width="sm"
                            filterMenu={
                                <TableCell.FilteringDate
                                    testId="filterMenu.startDate"
                                    date={requestFilter.startDate}
                                    onShow={handleOnShowFilterMenu("startDate")}
                                    onFilter={handleFilterDate("startDate")}
                                />
                            }
                        >
                            {t(k.START)}

                            {dateFilterLabels["startDate"] && (
                                <span className="filter-label">
                                    ({dateFilterLabels["startDate"]})
                                </span>
                            )}
                        </TableCell>
                        <TableCell
                            name={ChecklistReportSortBy.EndDate}
                            onClick={handleSort}
                            sortingBy={sort.sortBy}
                            sortingIsAsc={sort.sortAsc}
                            zIndex={visibleMenuParentCellZIndex["endDate"]}
                            width="sm"
                            filterMenu={
                                <TableCell.FilteringDate
                                    testId="filterMenu.endDate"
                                    date={requestFilter.endDate}
                                    onShow={handleOnShowFilterMenu("endDate")}
                                    onFilter={handleFilterDate("endDate")}
                                />
                            }
                        >
                            {t(k.DUE)}

                            {dateFilterLabels["endDate"] && (
                                <span className="filter-label">
                                    ({dateFilterLabels["endDate"]})
                                </span>
                            )}
                        </TableCell>

                        <TableCell
                            name={ChecklistReportSortBy.Version}
                            onClick={handleSort}
                            sortingBy={sort.sortBy}
                            sortingIsAsc={sort.sortAsc}
                        >
                            {t(k.VERSION)}
                        </TableCell>

                        <TableCell
                            zIndex={visibleMenuParentCellZIndex["status"]}
                            filterMenu={
                                <ChecklistStatusFilter
                                    isAdministrator={isAdministrator}
                                    values={requestFilter.status ?? []}
                                    onShow={handleOnShowFilterMenu("status")}
                                    onChange={handleFilterByStatus}
                                />
                            }
                        >
                            {t(k.STATUS)}
                            &nbsp;
                            <InfoIcon
                                size="small"
                                tooltip={{
                                    id: `tootip-${templateVersionId}-status`,
                                    message: statusFilterLabel,
                                    alignSelf: "center",
                                }}
                            />
                        </TableCell>

                        <TableCell>{t(k.STEPS_DONE)}</TableCell>

                        <TableCell>{t(k.IMPROVEMENTS)}</TableCell>

                        {tableMatrixValue.isInitialized && (
                            <SeparateTablesCell />
                        )}

                        {tableMatrixValue.isInitialized && (
                            <LevelTwoReportDetailsHeader
                                customListFilters={
                                    requestFilter.customListFilters ?? null
                                }
                                columns={tableMatrixValue.columns}
                                onFilter={handleFilterByCustomLists}
                                onExpand={handleExpandHeader}
                            />
                        )}
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {checklistRows.map((row) => (
                        <ReportTableRow
                            key={row.id}
                            value={row}
                            tableMatrixData={tableMatrixValue}
                            selectedCustomListItemsNames={
                                selectedCustomListItemsNames
                            }
                            isAdministrator={isAdministrator}
                            onSelectAction={handleActionSelect}
                        />
                    ))}
                </TableBody>

                {reportDetails && (
                    <TableFooter>
                        <TableRow>
                            <TableCell
                                key="sum-cell_matches"
                                colSpan={
                                    WORKSHEET_REPORT_COLSPAN -
                                    (showMatchResultsColumn ? 1 : 2)
                                }
                            >
                                {t(k.TOTAL_VALUES_FOR_ALL)}{" "}
                                {checklistReportDto?.paginatedData.totalCount}{" "}
                                {t(k.MATCHING_CHECKLISTS_)}
                            </TableCell>

                            <TableCell
                                colSpan={2}
                                key="sum-cell_imp"
                                className="improvements-summary"
                            >
                                {checklistReportDto && (
                                    <React.Fragment>
                                        {
                                            checklistReportDto.totalNotCompletedImprovementsCount
                                        }{" "}
                                        {t(k.OPEN)},{" "}
                                        {
                                            checklistReportDto.totalCompletedImprovementsCount
                                        }{" "}
                                        {t(k.COMPLETED)}
                                    </React.Fragment>
                                )}
                            </TableCell>

                            <SeparateTablesCell key="sum-cell_separation" />

                            <ReportTableSumCells
                                tableMatrixData={tableMatrixValue}
                                sumFilter={valueSumFilter}
                                sumOfValues={sumOfValuesResponse.data ?? null}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </div>
    );
};

const actions = {
    showConfirm,
};

export default connect(null, actions)(ReportTable);
